import "./intro.scss";
import { React, useEffect, useRef } from "react";
import { init } from "ityped";
const Intro = () => {
  const textRef = useRef();
  const textRefSurName = useRef();
  const textSetting = {
    textShow: "Developer",
    backDelay: 1500,
    backSpeed: 60,
    showCursor: true,
  };
  const textSettingNameAndSurname = {
    textShow: "Thananun  Saelim",
    backDelay: 1000,
    backSpeed: 25,
    showCursor: false,
  }
  useEffect(() => {
    init(textRef.current, {
      showCursor: textSetting.showCursor,
      backDelay: textSetting.backDelay,
      backSpeed: textSetting.backSpeed,
      strings: [textSetting.textShow],
    });
    init(textRefSurName.current, {
      showCursor: textSettingNameAndSurname.showCursor,
      backDelay: textSettingNameAndSurname.backDelay,
      backSpeed: textSettingNameAndSurname.backSpeed,
      strings: [textSettingNameAndSurname.textShow],
    });
  }, []);
  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="assets/man2.png" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi There, I'm</h2>
          <h1 ref={textRefSurName}></h1>
          <h3> Programer And <span ref={textRef}></span></h3>
        </div>
        <a href="#portfolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  );
};

export default Intro;
