import "./resume.scss";
import {dataForResume} from "../../data";
const Testimonials = () => {
    const data = dataForResume;
  return (
    <div className="testimonials" id="testimonials">
      <h1>Resume</h1>
      <div className="container">
          {
              data.map((d) => ( 
        <div className={d.featured ? "card featured" : "card"} key={d.id}>
          <div className="top">
            <img src="assets/right-arrow.png" alt="" className="left" />
            <img className="user img-profile"  src="assets/man2.png" alt="" />
            <img className="right" src={d.icon} alt="" />
          </div>
          <div className="center">
            {d.desc}
            <a href="assets/myresume.pdf" download=""><button><i class="fa fa-cloud-download"></i> </button></a>
          </div>
          <div className="bottom">
              <h3>{d.name}</h3>
              <h4>{d.title}</h4>
          </div>
        </div>
           ))
        }
      </div>
    </div>
  );
};

export default Testimonials;
